<template>
  <section>
    <div class="leaf-background">
      <div class="content-wrapper no-background">
        <!-- <div class="row"> -->
        <!-- <div class="col-md-12 d-flex justify-content-center"> -->
        <stringtext
          v-for="(description, descriptionId) in data.text"
          :key="'title :' + descriptionId"
          :items="description"
          :edit="false"
          :editable="editable"
          :description-index="data.text.indexOf(description)"
          @remove-text="data.text.splice(data.text.indexOf($event), 1)"
          @push-text="data.text.splice($event['index'], 0, $event['text'])"
          @typographie-text="textTypographie(description, $event)"
        >
        </stringtext>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import stringtext from "../element/string-text.vue";

export default {
  name: "text-type-1",
  components: {
    stringtext,
  },
  props: {
    data: Object,
    editable: Boolean,
    sectionTitle: String,
  },
  methods: {
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
  },
};
</script>

<style scoped>
.leaf-background {
  position: relative;
  min-height: 30rem;
  background-image: url("../../assets/images/import/meb/branche-3.png"),
    url("../../assets/images/import/meb/branche-4.png");
  background-size: 38%, 50%;
  background-position: center left -17rem, center right -19rem;
  background-repeat: no-repeat;
}
</style>